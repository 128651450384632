import React from 'react'
import * as styles from './slider-container.module.scss'

import { SliderView } from '../RecipeCollection/RecipeCollection'

const SliderContainer = ({
    array
}) => {
    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Suggestions</h3>
            <SliderView array={array} />
        </div>
    )
}

export default SliderContainer